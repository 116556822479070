import { memo } from "react";
import Styles from "./Spinner.module.scss";
import { useSpring, animated } from "react-spring";

function Spinner(props) {
  const data = [
    {
      cx: "50%",
      cy: "20%",
    },
    {
      cx: "76%",
      cy: "35%",
    },
    {
      cx: "76%",
      cy: "65%",
    },
    {
      cx: "50%",
      cy: "80%",
    },
    {
      cx: "24%",
      cy: "65%",
    },
    {
      cx: "24%",
      cy: "35%",
    },
  ];

  const spring = useSpring({
    from: { rotate: 0, opacity: 1 },

    to: async (animate, cancel) => {
      await animate({
        to: { opacity: 1, rotate: props.isMovedByArrow ? 0 : 180 },
        config: { duration: props.isMovedByArrow ? 200 : 13000 },
        // onStart: () => {
        //   console.log("start rotate");
        // },
      });

      await animate({
        from: { opacity: 1 },
        to: { opacity: 0 },
        config: { duration: 500 },
        // onStart: () => {
        //   console.log("start opacity");
        // },
      });
    },
    // reverse: props.isMovedByArrow,
    // pause: props.isMovedByArrow,
    reset: props.startInnerSpinner, //|| props.isMovedByArrow,
    // config: { duration: props.isMovedByArrow ? 200 : 3000 },
    // config: { duration: 3000 },
    // onStart: () => {
    //   console.log("start all");
    // },
  });

  return (
    <div className={Styles.container}>
      <svg
        className={`${Styles.circleInnerProgress} ${props.color}`}
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        viewBox="0 0 1000 1000"
      >
        <animated.g style={spring}>
          <circle
            cx="50%"
            cy="50%"
            r="200"
            strokeDashoffset="50"
            pathLength="100"
            className={`${props.startInnerSpinner ? Styles.play : ""} ${
              props.isMovedByArrow ? Styles.movedByArrow : ""
            }`}
          />
        </animated.g>
      </svg>
      <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 1000 1000" className={props.color}>
        <g className={Styles.inner} style={{ transform: `rotate(${props.rotation}deg)` }}>
          <circle className={`${Styles.circleMain}`} cx="50%" cy="50%" r="300" />
          <circle className={`${Styles.circleInner}`} cx="50%" cy="50%" r="200" />
          {data.map((item, index) => (
            <circle
              key={index}
              className={`${Styles.circle} ${props.activeBullet === index ? Styles.isActive : ""}`}
              cx={item.cx}
              cy={item.cy}
              r={props.activeBullet === index ? "25" : "15"}
            />
          ))}
        </g>
      </svg>
    </div>
  );
}

export default memo(Spinner);
