import Image from "next/image";
import Styles from "styles/Index.module.scss";

import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
import { useState } from "react";

import IndexLayout from "components/IndexLayout/IndexLayout";
import Header from "components/Header/Header";
import Slider from "components/Slider/Slider";
import Sidepage from "components/Sidepage/Sidepage";
import Footer from "components/Footer/Footer";
import Social from "components/Social/Social";
// import { Tooltip } from "react-tooltip";

import "swiper/css";
// import "react-tooltip/dist/react-tooltip.css";

export default function Home() {
  const [activeSidePage, setActiveSidePage] = useState(null);

  const technologies = [
    "JavaScript",
    "HTML",
    "CSS",
    "Sass",
    "WordPress",
    "PHP",
    "MySQL",
    "Laravel",
    "React",
    "Vue",
    "Next.js",
    "Node.js",
  ];
  return (
    <div className={Styles.container}>
      <div className={`${Styles.wrapper} ${activeSidePage ? Styles.sidePageActive : ""}`}>
        <Header />
        {/* https://www.magicpattern.design/tools/blurry-gradients */}
        {/* <Background className={`${Styles.background} ${color}`} /> */}
        <div className={Styles.bg}>
          <span></span>
          <span></span>
          <span></span>
        </div>
        {/* Side connect */}
        <div className="absolute rotate-90 top-1/2 transform -translate-y-1/2 -translate-x-1/2 left-8 items-center gap-6 text-stone-400 hidden md:flex z-10">
          <span className="w-24 h-px bg-stone-400"></span>
          <span>Connect</span>
          <Social size={20} wrapperClassName="ml-4 flex-col -rotate-90" iconClassName="w-6 h-6" />
        </div>
        {/* Side technologies */}
        <div className="absolute -rotate-90 top-1/2 transform -translate-y-1/2 translate-x-1/2 right-8 items-center gap-6 text-stone-400 hidden md:flex z-10 ">
          <span className="w-8 h-px bg-stone-400"></span>
          <span>Technologies</span>
          <div className="ml-14 relative h-36 rotate-90">
            <div className="h-8 w-full absolute bg-gradient-to-b -top-1 left-0 from-stone-800 z-10"></div>
            <div className="h-8 w-full absolute bg-gradient-to-b -bottom-1 left-0 from-stone-800 z-10 rotate-180"></div>
            <Swiper
              spaceBetween={20}
              slidesPerView={3}
              autoplay={{ delay: 200, disableOnInteraction: false }}
              speed={1000}
              loop={true}
              direction={"vertical"}
              allowTouchMove={false}
              modules={[Autoplay]}
              className="h-full"
              centeredSlides={true}
            >
              {technologies.map((technology, index) => (
                <SwiperSlide key={index}>
                  {/* <Tooltip anchorId={`icon-dev-${index + 1}`} content={technology} place="left" /> */}
                  <Image
                    // id={`icon-dev-${index + 1}`}
                    src={`/images/dev-icons/icon-dev-${index + 1}.svg`}
                    alt={`${technologies[index]} logo`}
                    width={30}
                    height={30}
                    className="grayscale"
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>

        <Slider />

        <Footer
          changeActiveSidePage={(page) => {
            setActiveSidePage(page);
          }}
        />
      </div>
      <Sidepage
        activeSidePage={activeSidePage}
        resetActiveSidePage={() => {
          setActiveSidePage(null);
        }}
        component="PageContact"
        transform="translateX(-100%)"
      />
      <Sidepage
        activeSidePage={activeSidePage}
        resetActiveSidePage={() => {
          setActiveSidePage(null);
        }}
        component="PageWork"
        transform="translateX(100%)"
      />
    </div>
  );
}

Home.getLayout = function getLayout(page) {
  return <IndexLayout>{page}</IndexLayout>;
};
