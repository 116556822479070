import { memo } from "react";
import dynamic from "next/dynamic";
import Styles from "./Sidepage.module.scss";
import { ArrowLeft } from "react-feather";
import { Scrollbars } from "react-custom-scrollbars-2";

const Sidepage = (props) => {
  const isActive = props.activeSidePage === props.component;
  const Component = dynamic(() => import(`components/${props.component}/${props.component}`));
  return (
    <div style={{ transform: props.transform }} className={`${Styles.container} ${isActive ? Styles.isActive : ""}`}>
      <Scrollbars universal>
        <div className="overflow-hidden">
          <div className={Styles.closeContainer}>
            <button className={Styles.close} onClick={() => props.resetActiveSidePage()}>
              <ArrowLeft />
              <span>Go back</span>
            </button>
          </div>
          <Component isActive={isActive} />
        </div>
      </Scrollbars>
    </div>
  );
};

export default memo(Sidepage);
