import Image from "next/image";

import { useState, useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectFade, Navigation, Autoplay, Pagination } from "swiper";

import Spinner from "components/Spinner/Spinner";

import Styles from "./Slider.module.scss";
import "swiper/css";
import "swiper/css/effect-fade";

const experience = new Date(new Date().getTime() - new Date(2016, 0, 1).getTime()).getFullYear() - 1970;

export default function Slider() {
  const swiperRef = useRef();
  const [color, setColor] = useState("text-lime-400");
  const [rotation, setRotation] = useState(0);
  const [activeBullet, setActiveBullet] = useState(0);
  const [startInnerSpinner, setStartInnerSpinner] = useState(false);
  const [isMovedByArrow, setIsMovedByArrow] = useState(false);

  const data = [
    {
      color: "text-lime-400",
      icon: "tree.svg",
      iconAlt: "Tree icon",
      title: "Hey, I'm Bar, a fullstack web developer specializing in front end development",
      content:
        "<p>I'm a JavaScript-focused developer that can implement a wide range of solutions from simple landing pages to complex web applications</p>",
    },
    {
      color: "text-rose-400",
      icon: "cherry.svg",
      iconAlt: "Cherry icon",
      title: "Driven to explore and use modern tools and technologies to build the best possible solutions",
      content:
        "<p>I strongly believe in the power of the web and the possibilities it offers. I am always looking for ways to improve my skills and learn new technologies</p>",
    },
    {
      color: "text-amber-400",
      icon: "bolt.svg",
      iconAlt: "Bolt icon",
      title: "So far, I've worked with a wide range of clients and companies from all over the world",
      content: `<p>With over ${experience} years of experience, I've learn to manage high-scale projects and to adapt to different methodologies and workflows</p>`,
    },
    {
      color: "text-purple-400",
      icon: "disk.svg",
      iconAlt: "disk icon",
      title: "I'm always looking for new challenges and opportunities to that will make the web a better place",
      content:
        "<p>My goal is to create a positive impact on the web and to help people and businesses to achieve their goals in a way that is both efficient and enjoyable</p>",
    },
  ];

  return (
    <>
      {/* Main slide */}
      <div className={Styles.swiperContainerWrapper}>
        <Swiper
          className={Styles.swiperContainer}
          modules={[EffectFade, Navigation, Autoplay, Pagination]}
          loop={true}
          effect="fade"
          fadeEffect={{ crossFade: true }}
          slidesPerView={1}
          slideActiveClass={`swiper-slide-active ${Styles.slideActive}`}
          a11y={{
            prevSlideMessage: "Previous slide",
            nextSlideMessage: "Next slide",
          }}
          speed={1000}
          allowTouchMove={false}
          autoplay={{ delay: 13000, disableOnInteraction: false }}
          pagination={{
            el: ".swiper-pagination",
            type: "custom",
            renderCustom: function (swiper, current, total) {
              return current + "<span></span>" + total;
            },
          }}
          onBeforeInit={(swiper) => {
            swiperRef.current = swiper;
          }}
          onInit={() => {
            setStartInnerSpinner(true);
          }}
          onRealIndexChange={(swiper) => {
            // console.log("onRealIndexChange");
            setColor(swiper.slides[swiper.activeIndex].dataset.color);
            setStartInnerSpinner(false);
            if (swiper.previousIndex - swiper.activeIndex > 0) {
              setRotation((prevRotation) => prevRotation + 60);
              activeBullet === 0 ? setActiveBullet(5) : setActiveBullet(activeBullet - 1);
            } else {
              setRotation((prevRotation) => prevRotation - 60);
              activeBullet === 5 ? setActiveBullet(0) : setActiveBullet(activeBullet + 1);
            }
          }}
          // onSlideChangeTransitionStart={() => {
          //   console.log("onSlideChangeTransitionStart");
          // }}
          onSlideChangeTransitionEnd={() => {
            // console.log("onSlideChangeTransitionEnd");
            setStartInnerSpinner(true);
            setIsMovedByArrow(false);
            // setTimeout(() => {
            // }, -1);
          }}
        >
          {data.map((item, index) => (
            <SwiperSlide key={index} className={Styles.swiperSlide} data-color={item.color}>
              <div className={Styles.slideSidebar}>
                <div className={Styles.slideIcon}>
                  <Image
                    src={`/images/${item.icon}`}
                    alt={item.iconAlt}
                    fill
                    sizes="100vw"
                    style={{
                      objectFit: "contain",
                    }}
                  />
                </div>
                <div className={Styles.swiperNavigation}>
                  <button
                    data-sound="click"
                    onClick={() => {
                      setIsMovedByArrow(true);
                      swiperRef.current?.slideNext();
                    }}
                  >
                    <Image src="/images/arrow.svg" fill alt="Next arrow" />
                  </button>
                  <button
                    data-sound="click"
                    onClick={() => {
                      setIsMovedByArrow(true);
                      swiperRef.current?.slidePrev();
                    }}
                  >
                    <Image src="/images/arrow.svg" fill alt="Prev arrow" />
                  </button>
                </div>
              </div>
              <div className={Styles.slideContent}>
                {index === 0 ? <h1>{item.title}</h1> : <h2>{item.title}</h2>}
                <div dangerouslySetInnerHTML={{ __html: item.content }}></div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      {/* Slider desktop navigation */}
      <div className={Styles.swiperNavigationDesktop}>
        <button
          data-sound="click"
          onClick={() => {
            setIsMovedByArrow(true);
            swiperRef.current?.slideNext();
          }}
        >
          <Image src="/images/arrow.svg" fill alt="Next arrow" />
        </button>
        <button
          data-sound="click"
          onClick={() => {
            setIsMovedByArrow(true);
            swiperRef.current?.slidePrev();
          }}
        >
          <Image src="/images/arrow.svg" fill alt="Prev arrow" />
        </button>
      </div>

      <Spinner
        color={color}
        rotation={rotation}
        activeBullet={activeBullet}
        startInnerSpinner={startInnerSpinner}
        isMovedByArrow={isMovedByArrow}
      ></Spinner>

      <div className={`swiper-pagination ${Styles.swiperPagination}`}></div>
    </>
  );
}
