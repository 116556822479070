import Image from "next/image";
import Styles from "./Footer.module.scss";

export default function Footer(props) {
  return (
    <div className={`${Styles.container} `}>
      <button data-sound="swoosh" onClick={() => props.changeActiveSidePage("PageContact")}>
        <div className="relative w-10 h-10 md:w-14 md:h-14">
          <Image src="/images/contact.svg" alt="Contact us icon" fill />
        </div>

        <span>
          Let{"'"}s
          <br />
          talk
        </span>
      </button>
      <button data-sound="swoosh" onClick={() => props.changeActiveSidePage("PageWork")}>
        <div className="relative w-10 h-10 md:w-14 md:h-14">
          <Image src="/images/technology.svg" alt="Technology icon" fill />
        </div>
        <span>
          Recent
          <br />
          work
        </span>
      </button>
    </div>
  );
}
